import React from 'react'
import { graphql } from 'gatsby'
import { Box } from '@xstyled/styled-components'

import Container from '../components/layout/container'
import Layout from '../components/layout/layout'
import NavBack from '../components/nav-back'
import SEO from '../components/seo'

// const SubTitle = styled.h5`
//   ${up(
//     'md',
//     css`
//       margin-bottom: 72rpx;
//     `
//   )}
// `

const ThankYouPage = ({ data, pageContext }) => {
  const lowerLang = pageContext.lang.toLowerCase()
  let capLang = lowerLang.charAt(0).toUpperCase() + lowerLang.charAt(1)
  capLang = capLang === 'Zh' ? 'ZhHant' : capLang
  const translatedData = data[`all${capLang}Json`].edges[0].node
  const { thankyou } = translatedData

  return (
    <Layout lang={pageContext}>
      <SEO title={thankyou.title} />
      <Container>
        <Box row justifyContent="center">
          <Box col={{ xs: 1, md: 3 / 4, xl: 2 / 3 }}>
            <NavBack linkText={translatedData.cta.back} />
            <h1>{thankyou.title}</h1>
            {/* <SubTitle>{thankyou.subtitle}</SubTitle> */}
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}

export default ThankYouPage

export const query = graphql`
  query {
    allEnJson {
      edges {
        node {
          cta {
            back
            learnMore
            downloadPlan
            downloadFullPlan
          }
          footerCopyright
          footerNav {
            link
            name
          }
          footerNewsLetter {
            placeHolder
            thankYou
            title
          }
          lightbox {
            cta {
              carousel
              enlarge
            }
          }
          mainNav {
            link
            name
          }
          contact {
            title
            subtitle
            email {
              label
              placeHolder
            }
            message {
              label
              placeHolder
            }
            subject {
              placeHolder
              label
            }
            submit
          }
          thankyou {
            subtitle
            title
          }
          newsEvents {
            cta {
              events
              news
              register
              viewAll
            }
            filter {
              all
              events
              news
              showing
            }
            title
            indexTitle
          }
          siteMetaData {
            author
            description
            title
          }
          translations {
            cta
            languages {
              link
              name
            }
          }
        }
      }
    }
    allEsJson {
      edges {
        node {
          cta {
            back
            learnMore
            downloadPlan
            downloadFullPlan
          }
          footerCopyright
          footerNav {
            link
            name
          }
          footerNewsLetter {
            placeHolder
            thankYou
            title
          }
          lightbox {
            cta {
              carousel
              enlarge
            }
          }
          mainNav {
            link
            name
          }
          contact {
            title
            subtitle
            email {
              label
              placeHolder
            }
            message {
              label
              placeHolder
            }
            subject {
              placeHolder
              label
            }
            submit
          }
          thankyou {
            subtitle
            title
          }
          newsEvents {
            cta {
              events
              news
              register
              viewAll
            }
            filter {
              all
              events
              news
              showing
            }
            title
            indexTitle
          }
          siteMetaData {
            author
            description
            title
          }
          translations {
            cta
            languages {
              link
              name
            }
          }
        }
      }
    }
    allKoJson {
      edges {
        node {
          cta {
            back
            learnMore
            downloadPlan
            downloadFullPlan
          }
          footerCopyright
          footerNav {
            link
            name
          }
          footerNewsLetter {
            placeHolder
            thankYou
            title
          }
          lightbox {
            cta {
              carousel
              enlarge
            }
          }
          mainNav {
            link
            name
          }
          contact {
            title
            subtitle
            email {
              label
              placeHolder
            }
            message {
              label
              placeHolder
            }
            subject {
              placeHolder
              label
            }
            submit
          }
          thankyou {
            subtitle
            title
          }
          newsEvents {
            cta {
              events
              news
              register
              viewAll
            }
            filter {
              all
              events
              news
              showing
            }
            title
            indexTitle
          }
          siteMetaData {
            author
            description
            title
          }
          translations {
            cta
            languages {
              link
              name
            }
          }
        }
      }
    }
    allBnJson {
      edges {
        node {
          cta {
            back
            learnMore
            downloadPlan
            downloadFullPlan
          }
          footerCopyright
          footerNav {
            link
            name
          }
          footerNewsLetter {
            placeHolder
            thankYou
            title
          }
          lightbox {
            cta {
              carousel
              enlarge
            }
          }
          mainNav {
            link
            name
          }
          contact {
            title
            subtitle
            email {
              label
              placeHolder
            }
            message {
              label
              placeHolder
            }
            subject {
              placeHolder
              label
            }
            submit
          }
          thankyou {
            subtitle
            title
          }
          newsEvents {
            cta {
              events
              news
              register
              viewAll
            }
            filter {
              all
              events
              news
              showing
            }
            title
            indexTitle
          }
          siteMetaData {
            author
            description
            title
          }
          translations {
            cta
            languages {
              link
              name
            }
          }
        }
      }
    }
    allArJson {
      edges {
        node {
          cta {
            back
            learnMore
            downloadPlan
            downloadFullPlan
          }
          footerCopyright
          footerNav {
            link
            name
          }
          footerNewsLetter {
            placeHolder
            thankYou
            title
          }
          lightbox {
            cta {
              carousel
              enlarge
            }
          }
          mainNav {
            link
            name
          }
          contact {
            title
            subtitle
            email {
              label
              placeHolder
            }
            message {
              label
              placeHolder
            }
            subject {
              placeHolder
              label
            }
            submit
          }
          thankyou {
            subtitle
            title
          }
          newsEvents {
            cta {
              events
              news
              register
              viewAll
            }
            filter {
              all
              events
              news
              showing
            }
            title
            indexTitle
          }
          siteMetaData {
            author
            description
            title
          }
          translations {
            cta
            languages {
              link
              name
            }
          }
        }
      }
    }
    allZhHantJson {
      edges {
        node {
          cta {
            back
            learnMore
            downloadPlan
            downloadFullPlan
          }
          footerCopyright
          footerNav {
            link
            name
          }
          footerNewsLetter {
            placeHolder
            thankYou
            title
          }
          lightbox {
            cta {
              carousel
              enlarge
            }
          }
          mainNav {
            link
            name
          }
          contact {
            title
            subtitle
            email {
              label
              placeHolder
            }
            message {
              label
              placeHolder
            }
            subject {
              placeHolder
              label
            }
            submit
          }
          thankyou {
            subtitle
            title
          }
          newsEvents {
            cta {
              events
              news
              register
              viewAll
            }
            filter {
              all
              events
              news
              showing
            }
            title
            indexTitle
          }
          siteMetaData {
            author
            description
            title
          }
          translations {
            cta
            languages {
              link
              name
            }
          }
        }
      }
    }
  }
`
